import { gsap } from "gsap";

class TabFiltering {
  constructor(elms) {
    this.dom = {trigger: elms};

    this.current = document.querySelector('[data-tab="on"]');
    this.target = null;
    
    this.changeCategory = this.changeCategory.bind(this);

    this.init();
  }

  init() {
    [...this.dom.trigger].forEach(el => {
      el.addEventListener('click', this.changeCategory);
    })
  }

  changeCategory(e) {
    const currentTab = document.querySelector('[data-tab="on"]');
    const currentLabel = currentTab.dataset.target;
    this.current = document.querySelector(`[data-label="${currentLabel}"]`);
    currentTab.dataset.tab = 'off';
    e.target.dataset.tab = 'on';
    const label = e.target.dataset.target;
    this.target = document.querySelector(`[data-label="${label}"]`);
    this.fadeIn();
    this.fadeOut();
  }

  fadeOut() {
    const _this = this;
    this.current.dataset.state = 'hidden';
    // gsap.to(this.current, {
    //   opacity: 0,
    //   ease: 'power3.inOut',
    //   duration: .9,
    //   onComplete: () => {
    //     _this.fadeIn();
    //   }
    // })  
  }
  
  fadeIn() {
    this.target.dataset.state = 'show';
    // gsap.to(this.target, {
    //   opacity: 1,
    //   ease: 'power3.inOut',
    //   duration: .9,
    //   // onComplete: () => {
    //   //   el.dataset.show = true;
    //   // }
    // })  
  }
}

export default TabFiltering