import ModalVideo from 'modal-video';
import 'modal-video/css/modal-video.css';

export default class {
  constructor(el) {
    this.dom = {el: el};

    this.id = this.dom.el[0].dataset.videoId;

    this.options = {
      channel: 'youtube',
      youtube: {
        rel: 0, // 関連動画の指定
        autoplay: 1, // 自動再生の指定
        playlist: this.id,
        loop: 1,
        // width: 1000,
        maxwidth: 1200,
      },
    }

    this.init();
  }

  init() {
    new ModalVideo(this.dom.el, this.options);
  }
}