class ElementShuffle {
  constructor(el, className) {
    this.dom = {el: el};
    // this.dom.wrapper = this.dom.el.querySelector('[data-shuffle="wrapper"]');
    this.dom.wrapper = this.dom.el;
    this.dom.container = this.dom.el.querySelector('[data-shuffle="container"]');
    this.dom.elm = this.dom.el.querySelectorAll('[data-shuffle="elm"]');

    this.class = className;
    this.cassette = null;

    this.init();
  }

  init() {
    const container = document.createElement('div');
    container.classList.add(this.class);

    this.cassette = this.shuffle(this.dom.elm);
    [...this.cassette].forEach(el=>{
      container.appendChild(el);
    });
    this.dom.wrapper.replaceChild(container, this.dom.container);
  }

  shuffle([...elms]) {
    for (let i = elms.length - 1; i >= 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [elms[i], elms[j]] = [elms[j], elms[i]];
    }
    return elms;
  }

  destroy() {
    this.slider.destroy(true, true);
  }
}

export default ElementShuffle