import { ElementShuffle } from '../_main';
import KeenSlider from 'keen-slider'
import 'keen-slider/keen-slider.min.css'

class ListSlider {
  constructor(el) {
    this.dom = {el: el};

    this.contents = this.dom.el.dataset.js;
    
    this.mql = window.matchMedia('screen and (min-width: 768px)');
    this.checkBreakPoint = this.checkBreakPoint.bind(this);

    this.dataJs = this.dom.el.dataset.js;

    this.slider = null;
    this.option = {
      // loop: true,
      // mode: 'free',
      defaultAnimation: {
        duration: 1200,
      },
      slides: {
        // origin: 'center',
        perView: 375 / 280,
      },
      breakpoints: {
        '(min-width: 768px)': {
          slides: {
            // origin: 'auto',
            perView: 1166 / 360,
          },
        },
      },
      // created: () => {
      //   console.log('created')
      // } 
    }

    this.plugin = [
      (slider) => {
        let timeout
        let mouseOver = false
        function clearNextTimeout() {
          clearTimeout(timeout)
        }
        function nextTimeout() {
          clearTimeout(timeout)
          if (mouseOver) return
          timeout = setTimeout(() => {
            slider.next()
          }, 3000)
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true
            clearNextTimeout()
          })
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false
            nextTimeout()
          })
          nextTimeout()
        })
        slider.on('dragStarted', clearNextTimeout)
        slider.on('animationEnded', nextTimeout)
        slider.on('updated', nextTimeout)
      },
    ]

    this.init();
  }

  init() {
    function navigation(slider) {
      let wrapper, dots, arrowLeft, arrowRight
    
      function markup(remove) {
        wrapperMarkup(remove)
        dotMarkup(remove)
        arrowMarkup(remove)
      }
    
      function removeElement(elment) {
        elment.parentNode.removeChild(elment)
      }
      function createDiv(className) {
        let div = document.createElement("div")
        let classNames = className.split(" ")
        classNames.forEach((name) => div.classList.add(name))
        return div
      }
    
      function arrowMarkup(remove) {
        if (remove) {
          removeElement(arrowLeft)
          removeElement(arrowRight)
          return
        }
        const arrow = '<svg viewBox="0 0 34 65"><use xlink:href="#arrow-slide-thin"></use></svg>';
        arrowLeft = createDiv('navigation__arrow')
        arrowLeft.dataset.dir = 'prev'
        arrowLeft.insertAdjacentHTML('beforeend', arrow);
        arrowLeft.addEventListener('click', () => slider.prev())
        arrowRight = createDiv('navigation__arrow')
        arrowRight.dataset.dir = 'next'
        arrowRight.insertAdjacentHTML('beforeend', arrow);
        arrowRight.addEventListener('click', () => slider.next())
    
        wrapper.appendChild(arrowLeft)
        wrapper.appendChild(arrowRight)
      }
    
      function wrapperMarkup(remove) {
        if (remove) {
          let parent = wrapper.parentNode
          while (wrapper.firstChild)
            parent.insertBefore(wrapper.firstChild, wrapper)
          removeElement(wrapper)
          return
        }
        wrapper = createDiv("navigation-wrapper")
        slider.container.parentNode.appendChild(wrapper)
        wrapper.appendChild(slider.container)
      }
    
      function dotMarkup(remove) {
        if (remove) {
          removeElement(dots)
          return
        }
        dots = createDiv("dots")
        slider.track.details.slides.forEach((_e, idx) => {
          let dot = createDiv("dot")
          dot.addEventListener("click", () => slider.moveToIdx(idx))
          dots.appendChild(dot)
        })
        wrapper.appendChild(dots)
      }
    
      function updateClasses() {
        let slide = slider.track.details.rel
        slide === 0
          ? arrowLeft.classList.add("is-disabled")
          : arrowLeft.classList.remove("is-disabled")
        slide === slider.track.details.slides.length - 1
          ? arrowRight.classList.add("is-disabled")
          : arrowRight.classList.remove("is-disabled")
        Array.from(dots.children).forEach(function (dot, idx) {
          idx === slide
            ? dot.classList.add("dot--active")
            : dot.classList.remove("dot--active")
        })
      }
    
      slider.on("created", () => {
        markup()
        updateClasses()
      })
      slider.on("optionsChanged", () => {
        console.log(2)
        markup(true)
        markup()
        updateClasses()
      })
      slider.on("slideChanged", () => {
        updateClasses()
      })
      slider.on("destroyed", () => {
        markup(true)
      })
    }

    // 240424 ランダム表示無しに変更
    // const regex = /dress/;
    // if (regex.test(this.contents)) {
    //   new ElementShuffle(this.dom.el, 'keen-slider');
    // }

    const slider = new KeenSlider(this.dom.el, this.option, [navigation])
    
    function WheelControls(slider) {
      let touchTimeout
      let position
      let wheelActive

      function dispatch(e, name) {
        position.x -= e.deltaX
        position.y -= e.deltaY
        slider.container.dispatchEvent(
          new CustomEvent(name, {
            detail: {
              x: position.x,
              y: position.y,
            },
          })
        )
      }

      function wheelStart(e) {
        position = {
          x: e.pageX,
          y: e.pageY,
        }
        console.log({position})
        dispatch(e, 'ksDragStart')
      }

      function wheel(e) {
        dispatch(e, 'ksDrag')
      }

      function wheelEnd(e) {
        dispatch(e, 'ksDragEnd')
      }

      function eventWheel(e) {
        console.log({e}, e.wheelDeltaY)
        if (e.wheelDeltaY < 3 && e.wheelDeltaY > -3) {
          e.preventDefault()
          if (!wheelActive) {
            wheelStart(e)
            wheelActive = true
          }
          wheel(e)
          clearTimeout(touchTimeout)
          touchTimeout = setTimeout(() => {
            wheelActive = false
            wheelEnd(e)
          }, 50)
        }
      }

      slider.on('created', () => {
        slider.container.addEventListener('wheel', eventWheel, {
          passive: false,
        })
      })
    }

    // // ブレイクポイントの瞬間に発火
    // this.mql.addListener(this.checkBreakPoint);
    // // 初回チェック
    // this.checkBreakPoint(this.mql);
  }

  checkBreakPoint(_mql) {
		if (!_mql.matches) {
      // console.log('sp');
      if (this.slider === null) {
        this.slider = new KeenSlider(this.dom.el, this.option, []);
      }
      return;
    } else {
      // console.log('pc');
      if (this.slider !== null && this.dataJs === 'slider-service') {
        this.slider.destroy();
        this.slider = null;
      } else if (this.slider === null && this.dataJs !== 'slider-service') {
        this.slider = new KeenSlider(this.dom.el, this.option, []);
      }
      return;
		}
	}
}

export default ListSlider