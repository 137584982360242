import KeenSlider from 'keen-slider'
import 'keen-slider/keen-slider.min.css'

class PlacesGallery {
  constructor(elms) {
    this.dom = {elms: elms};
    this.dom.gallery = {main: [], thumbnail: []};

    this.dom.elms.forEach((el, i)=>{
      this.dom.gallery.main.push(el.querySelector('[data-js="gallery-main"]'));
      this.dom.gallery.thumbnail.push(el.querySelector('[data-js="gallery-thumbnail"]'));
    })
    // this.dom.gallery.thumbnail =this.dom.elms.querySelector('[data-js="gallery-thumbnail"]');

    this.mql = window.matchMedia('screen and (min-width: 768px)');
    this.checkBreakPoint = this.checkBreakPoint.bind(this);

    this.animationId = null;
    this.resizeObserver = null;
    this.slides = this.dom.elms.length;

    this.thumbnailPlugin = this.thumbnailPlugin.bind(this);
    this.slider = {main: [], thumbnail: []}

    this.option = {
      main: {
        loop: true,
        slides: {
          perView: 1,
        },
        defaultAnimation: {
          duration: 1200
        },
        detailsChanged: (s) => {
          s.slides.forEach((element, idx) => {
            element.style.opacity = s.track.details.slides[idx].portion
          })
        },
        // renderMode: 'performance', // 'precision' | 'performance' | 'custom'
        renderMode: 'custom',
        // breakpoints: {
        //   '(min-width: 768px)': {
        //     slides: {
        //       // origin: 'auto',
        //       perView: 1166 / 360,
        //     },
        //   },
        // },
        // created: () => {
        //   console.log('created')
        // }
      },
      thumbnail: {
        initial: 0,
        slides: {
          perView: 3,
          spacing: 3
          // spacing: `min(${3 / 16}rem, ${3 / 375 * 100}vw)`,
        }
      }
    }

    this.plugins = {
      autoplaySwitch: [
        (slider) => {
          let timeout
          let mouseOver = false
          function clearNextTimeout() {
            clearTimeout(timeout)
          }
          function nextTimeout() {
            clearTimeout(timeout)
            if (mouseOver) return
            timeout = setTimeout(() => {
              slider.next()
            }, 4000)
          }
          slider.on('created', () => {
            slider.container.addEventListener('mouseover', () => {
              mouseOver = true
              clearNextTimeout()
            })
            slider.container.addEventListener('mouseout', () => {
              mouseOver = false
              nextTimeout()
            })
            nextTimeout()
          })
          slider.on('dragStarted', clearNextTimeout)
          slider.on('animationEnded', nextTimeout)
          slider.on('updated', nextTimeout)
        },
      ]
    }

    this.init();
  }

  init() {
    [...this.dom.gallery.main].forEach((el, i) => {
      this.slider.main.push(new KeenSlider(this.dom.gallery.main[i], this.option.main, this.plugins.autoplaySwitch));
    });
    [...this.dom.gallery.thumbnail].forEach((el, i) => {
      this.slider.thumbnail.push(new KeenSlider(this.dom.gallery.thumbnail[i], this.option.thumbnail, [this.thumbnailPlugin(this.slider.main[i])]));
    });
    this.resizeObserverStart();

    // ブレイクポイントの瞬間に発火
    // this.mql.addListener(this.checkBreakPoint);
    // 初回チェック
    // this.checkBreakPoint(this.mql);
  }

  checkBreakPoint(_mql) {
		if (!_mql.matches) {
      // console.log('sp');
      // [...this.dom.gallery.main].forEach((el, i) => {
      //   this.slider.main.push(new KeenSlider(this.dom.gallery.main[i], this.option.main, this.plugins.autoplaySwitch));
      // });
      // [...this.dom.gallery.thumbnail].forEach((el, i) => {
      //   this.slider.thumbnail.push(new KeenSlider(this.dom.gallery.thumbnail[i], this.option.thumbnail, [this.thumbnailPlugin(this.slider.main[i])]));
      // });
      return;
    } else {
      // console.log('pc');
      // for (let i = 0; i < this.slides; ++i) {
      //   if (this.slider.main[i] !== null) this.slider.main[i].update();
      //   if (this.slider.thumbnail[i] !== null) this.slider.thumbnail[i].update();
      // }
      return;
		}
	}

  thumbnailPlugin(main) {
    return (slider) => {
      function removeActive() {
        slider.slides.forEach((slide) => {
          slide.classList.remove('active')
        })
      }
      function addActive(idx) {
        slider.slides[idx].classList.add('active')
      }
  
      function addClickEvents() {
        slider.slides.forEach((slide, idx) => {
          slide.addEventListener('click', () => {
            main.moveToIdx(idx)
          })
        })
      }
  
      slider.on('created', () => {
        addActive(slider.track.details.rel)
        addClickEvents()
        main.on('animationStarted', (main) => {
          removeActive()
          const next = main.animator.targetIdx || 0
          addActive(main.track.absToRel(next))
          slider.moveToIdx(Math.min(slider.track.details.maxIdx, next))
        })
      })
    }
  }

  resizeObserverStart() {
    this.resizeObserverCancel();
    // インスタンスの生成
    this.resizeObserver = new ResizeObserver((elements) => {
      this.animationId = requestAnimationFrame(() => {
        // 監視対象のサイズが変更される度この部分の処理が実行される
        for (let i = 0; i < this.slides; ++i) {
          this.slider.main[i].update();
          this.slider.thumbnail[i].update();
        }
      })
    });
    // 監視対象となる要素
    const target = document.querySelector('[data-container="places"]');
    // 監視を開始する構文
    this.resizeObserver.observe(target);
  }

  resizeObserverCancel() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;

      if (this.animationId != null) {
        cancelAnimationFrame(this.animationId);
        this.animationId = null;
      }
    }
  }
}

export default PlacesGallery