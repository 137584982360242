import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

class ImageParallax {
  constructor(el) {
    this.dom = {el: el};

    gsap.registerPlugin(ScrollTrigger);

    this.init();
  }

  init() {
    gsap.utils.toArray(this.dom.el).forEach(wrap => {
      const y = wrap.getAttribute('data-y') || -100;
      
      gsap.to(wrap, {
        y: y,    
        scrollTrigger: {
          trigger: wrap,
          start: 'top bottom',
          end: 'bottom top',
          scrub: 1.2,
          // markers: true
        }
      })
    });
  }
}

export default ImageParallax