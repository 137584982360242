export default class {
  constructor(el) {
    this.dom = {el: el};
    this.dom.body = document.querySelector('body');
    this.dom.scroller = this.dom.el;

    this.posPageHeader = 0;
    this.posPrevious = 0;
    this.hh = (this.dom.scroller) ? this.dom.scroller.clientHeight : 0;

    this.scrollAdj = 0;

    // this.dom.body.dataset.scrollDirection = 'down';
    // this.flagUp = false;
    this.flagUp = (this.dom.body.dataset.scrollDirection === 'false') ? false : true;

    this.mql = window.matchMedia('screen and (min-width: 768px)');
    this.checkBreakPoint = this.checkBreakPoint.bind(this);

    this.update = this.update.bind(this);
    this.resize = this.resize.bind(this);

    this.init();
  }

  init() {
    this.setVariable();
    this.update();
    window.addEventListener('resize', this.resize);

    // ブレイクポイントの瞬間に発火
    this.mql.addListener(this.checkBreakPoint);
    // 初回チェック
    this.checkBreakPoint(this.mql);
  }

  update() {
    // let pyo = window.pageYOffset + this.hh - this.dom.scroller.clientHeight;
    let pyo = window.pageYOffset;
    pyo = pyo < 0 ? 0 : pyo;

    this.flagUp = (this.dom.body.dataset.scrollAction !== 'true') ? false : true;

    if (pyo > this.posPageHeader) {
      document.body.dataset.scrollAction = 'true';
    } else {
      document.body.removeAttribute('data-scroll-action');
      this.dom.body.removeAttribute('data-scroll-direction');
    }

    if (this.flagUp) {
      if (pyo - this.posPrevious > this.scrollAdj) {
        this.dom.body.dataset.scrollDirection = 'down';
      }
    }

    if (this.posPrevious - pyo > this.scrollAdj) {
      this.dom.body.dataset.scrollDirection = 'up';
    }

    this.posPrevious = pyo;

    requestAnimationFrame(this.update);
  }

  // スクロールの基準となる位置を設定
  setVariable() {
    // ヘッダが隠れたと判断する位置
    this.hh = this.dom.scroller.clientHeight;
    // this.posPageHeader = 100;
  }

  resize() {
    this.setVariable();
  }

  checkBreakPoint(_mql) {
		if (!_mql.matches) {
      // console.log('sp');
      this.posPageHeader = window.innerHeight * .1;
      return;
    } else {
      // console.log('pc');
      this.posPageHeader = 60;
      return;
		}
	}
}
