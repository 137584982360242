import { gsap } from "gsap";

class AreaFiltering {
  constructor(el) {
    this.dom = {el: el};
    this.dom.trigger = this.dom.el.querySelectorAll('[data-trigger="area"]');
    this.dom.container = document.querySelector('[data-container="places"]');
    this.dom.places = null;

    this.len = null;
    this.area = null;
    
    this.checkedArea = this.checkedArea.bind(this);

    this.init();
  }

  init() {
    [...this.dom.trigger].forEach(el => {
      el.addEventListener('click', this.checkedArea);
    })
  }

  checkedArea(e) {
    const checkeElm = this.dom.el.querySelector('[data-is="checked"]');
    checkeElm.removeAttribute('data-is');
    e.target.dataset.is = 'checked';
    this.area = e.target.dataset.area;
    
    this.dom.places = (this.area !== 'all') ? this.dom.container.querySelectorAll('[data-area][data-show="true"]') : this.dom.container.querySelectorAll('[data-area]');
    this.len = this.dom.places.length;

    this.fadeOut();
  }

  fadeOut() {
    const _this = this;
    [...this.dom.places].forEach((el, i) => {
      gsap.to(el, {
        opacity: 0,
        // scale: .95,
        y: '-2.5%',
        ease: 'power3.inOut',
        duration: .9,
        // delay: .05 * i,
        onComplete: () => {
          if (i === this.len - 1) {
            const target = _this.dom.container.querySelectorAll(`[data-area]:not([data-area="${_this.area}"])`);
            [...target].forEach(el => {
              el.style.display = 'none';
              el.dataset.show = false;
            })
            _this.fadeIn();
          }
        }
      })  
    })
  }

  fadeIn() {
    const target = (this.area !== 'all') ? this.dom.container.querySelectorAll(`[data-area="${this.area}"]`) : this.dom.container.querySelectorAll('[data-area]');
    [...target].forEach((el, i) => {
      gsap.set(el, {
        display: 'block',
        y: '2.5%',
      }) 
      gsap.to(el, {
        opacity: 1,
        // scale: 1,
        y: '0%',
        ease: 'power3.inOut',
        duration: .9,
        delay: .05 * i,
        onComplete: () => {
          el.dataset.show = true;
        }
      })  
    })
  }
}

export default AreaFiltering