import { CFG } from '../config';

class AnchorScroll {
  constructor(el) {
    this.dom = {el: el};
    this.dom.header = document.querySelector('.st-header');

    this.hash = null;
    this.scroll = this.scroll.bind(this);

    this.init();
  }

  init() {
    const href = this.dom.el.getAttribute('href');

    this.hash = href.match(/\#.*$/)[0];
    if (this.hash.length > 1) {
      this.dom.el.addEventListener('click', this.scroll);
    }
  }

  scroll(e) {
    e.preventDefault();

    const target = document.querySelector(this.hash);

    if (/pagetop/.test(this.hash)) {
      target.scrollIntoView({behavior:'smooth'});
    } else {
      let hh = this.dom.header.clientHeight + 32;
      const rect = target.getBoundingClientRect().top;
      const pyo = window.pageYOffset;
      const top = rect + pyo - hh;
      // const top = rect + pyo;

      if (top == 0) {
        target.scrollIntoView({behavior:'smooth'});
      } else {
        window.scroll({top: top, behavior: 'smooth'});
      }
    }
  }

  destroy() {
    this.dom.el.removeEventListener('click', this.scroll);
  }
}

export default AnchorScroll