import { gsap } from "gsap";
import YouTubePlayer from 'youtube-player';

class OpeningAnimation {
  constructor(el) {
    this.dom = {el: el};
    this.dom.scene01 = this.dom.el.querySelector('[data-js="opening-scene-01"]');
    this.dom.scene02 = this.dom.el.querySelector('[data-js="opening-scene-02"]');
    this.dom.visual = document.querySelector('[data-js="visual"]');
    this.dom.openingMovie = this.dom.visual.querySelector('[data-js="opening-movie"]');
    this.dom.container = this.dom.visual.querySelector('[data-js="movie-container"]');
    this.dom.skipButton = this.dom.visual.querySelector('[data-js="skip"]');

    this.delay = 3;
    this.duration = 1.5;
    this.playTime = 8;

    this.player = null;

    this.io = null;
    this.ww = 0;
    this.timeout = 0;

    this.hash = location.hash;

    this.isIOS = /iPhone|iPad|iPod/i.test(window.navigator.userAgent);
    this.isAndroid = /Android/i.test(window.navigator.userAgent);
    this.isMobile = this.isIOS || this.isAndroid;

    this.check = this.check.bind(this);
    this.orientationchange = this.orientationchange.bind(this);
    this.checkScroll = this.checkScroll.bind(this);

    if (this.hash) this.dom.el.remove();

    this.period = 1000 * 60 * 60 * 24;    
    // 初回アクセスフラグのキー
    const firstAccessKey = 'firstAccessFlag';
    // 保存期間で使用する値
    const firstPeriodKey = 'firstPeriodTime';

    const init = () => {
      // 初回アクセスフラグをlocalStorageに保存
      localStorage.setItem(firstAccessKey, 'false');
      localStorage.setItem(firstPeriodKey, Date.now());
      this.init();
    }

    // 保存期間の値が保存されている場合
    if (localStorage.getItem(firstPeriodKey) !== null) {
      // 保存されている値と現在の値の差を見て、期間を過ぎていないか確認
      const diff = Date.now() - parseFloat(localStorage.getItem(firstPeriodKey));
      if (diff > this.period) {
        // 期間を過ぎている場合はlocalStorageの値を削除
        localStorage.removeItem(firstAccessKey);
        localStorage.removeItem(firstPeriodKey);
        init();
      } else {
        // 2回目以降のアクセス時の処理
        this.dom.el.remove();
        // this.dom.openingMovie.remove();
        // this.init();
        this.movieInit();
      }
    } else {
      // init();
      if (this.dom.el && !this.hash) init();
    }

    this.dom.openingMovie.style.display = 'block';
  }
  
  init() {
    const _this = this;
    this.player = new OpeningYoutube(this.dom.visual, true);
    this.pauseVideo();
    
    if (this.dom.visual) {
      this.check();
      window.addEventListener('resize', this.check);
      window.addEventListener('orientationchange', this.orientationchange);

      // const ioOptions = {};
      // this.io = new IntersectionObserver(this.checkScroll, ioOptions);
      // this.io.observe(this.dom.el);

      this.dom.el.style.display = 'block';
      // this.dom.openingMovie.style.display = 'block';
    }

    this.dom.skipButton.addEventListener('click', e => {
      window.scroll({top: 0, behavior: 'instant'});
      // this.dom.video.pause();
      this.pauseVideo();
      // tl.seek(tl.endTime() - this.duration);
      gsap.to(this.dom.openingMovie, {
        opacity: 0,
        onComplete: () => {
          this.dom.openingMovie.remove();
        }
      })
      document.body.dataset.opening = 'finished';
    })

    setTimeout(()=>{
      window.scroll({top: 0, behavior: 'instant'});
      this.openingStart();
    }, 100)
  }

  movieInit() {
    const _this = this;
    this.player = new OpeningYoutube(this.dom.visual, false);
    // this.pauseVideo();
    setTimeout(()=>{
    }, 3000)
    _this.playVideo();
    
    if (this.dom.visual) {
      window.addEventListener('resize', this.check);
      window.addEventListener('orientationchange', this.orientationchange);
    }
    
    this.dom.skipButton.addEventListener('click', e => {
      window.scroll({top: 0, behavior: 'instant'});
      this.pauseVideo();
      gsap.to(this.dom.openingMovie, {
        opacity: 0,
        onComplete: () => {
          this.dom.openingMovie.remove();
        }
      })
      document.body.dataset.opening = 'finished';
    })
    
    setTimeout(()=>{
      window.scroll({top: 0, behavior: 'instant'});
    }, 100)

    this.check();
  }

  openingStart() {
    const _this = this;
    const tl = gsap.timeline();
    tl.to(this.dom.scene01, {
      opacity: 0,
      ease: 'power1.inOut',
      duration: 1,
      delay: 1,
      onComplete: () => {
        setTimeout(()=>{
          _this.playVideo();
        }, 1000)
      }
    });
    tl.to(this.dom.scene02, {
      opacity: 0,
      ease: 'power1.inOut',
      duration: 1,
      delay: 1,
      onComplete: () => {
        this.dom.el.remove();
      }
    });
  }

  scrollDisable(_time) {
    setTimeout(()=>{
      document.body.dataset.isScrolldisable = 'true';
    }, _time);
  }

  check() {
    const wiw = window.innerWidth;
    const wih = window.innerHeight;
    const wSP = Math.floor(wiw - (wiw * .042666666667 * 2));
    const wPC = Math.floor(wiw - (wiw * .016837481698 * 2));
    const ww = (this.isMobile && wiw < 768) ? wSP : wPC;
    const wh = (this.isMobile && wiw < 768) ? wSP : document.querySelector('.hm-visual__inner').clientHeight;

    if (this.isMobile && ww < 768) {
      if (this.ww != ww) {
        this.dom.el.removeAttribute('style');
      }
    } else {
      this.dom.el.removeAttribute('style');
    }
    
    this.player.setSize(ww, wh);
    // backup state
    this.ww = ww;
  }

  orientationchange() {
    const self = this;
    const timeout = setTimeout(() => {
      self.check();
      clearTimeout(self.timeout);
      self.timeout = timeout;
    }, 500);
  }

  checkScroll(entries) {
    for (let i = 0, l = entries.length; i < l; i++) {
      const el = entries[i];
      if (el.isIntersecting) {
        this.playVideo();
      } else {
        this.pauseVideo();
      }
    }
  }

  playVideo() {
    this.player.play();
  }

  pauseVideo() {
    this.player.pause();
  }
}

class OpeningYoutube {
  constructor(el, opening) {
    this.dom = {el: el};
    this.dom.player = this.dom.el.querySelector('[data-js="youtube"]');
    this.dom.openingMovie = this.dom.el.querySelector('[data-js="opening-movie"]');
    this.dom.container = this.dom.el.querySelector('[data-js="movie-container"]');

    this.player = null;
    this.videoId = this.dom.player.dataset.videoid.split(',');
    this.videoId.pc = this.videoId[0];
    this.videoId.sp = this.videoId[1];

    this.opening = opening;

    this.isInit = false;
    this.isPlaying = false;

    this.device = null;
    this.mql = window.matchMedia('screen and (min-width: 768px)');
    this.checkBreakPoint = this.checkBreakPoint.bind(this);

    if (this.dom.el) this.init();
  }

  init() {
    const _this = this;
    // ブレイクポイントの瞬間に発火
    this.mql.addListener(this.checkBreakPoint);
    // 初回チェック
    this.checkBreakPoint(this.mql);
    
    // this.dom.player.style.opacity = 0;
    this.player = YouTubePlayer('opening-youtube', {
      videoId: (this.device === 'pc') ? this.videoId.pc : this.videoId.sp,
      width: (this.device === 'pc') ? '560' : '343',
      height: (this.device === 'pc') ? '315' : '343',
      playerVars: {
        controls: 0,
        disablekb: 0,
        fs: 0,
        iv_load_policy: 3,
        modestbranding: 1,
        showinfo: 0,
        rel: 0,
        // autoplay: 1,
        playlist: (this.device === 'pc') ? this.videoId.pc : this.videoId.sp,
        playsinline: 1,
        // loop: 1,
        // end: 10, // 10sで終了
      },
    });

    this.player.on('ready', e => {
      this.player.mute();
      this.player.playVideo();
      this.isPlaying = true;

      const raf = () => {
        requestAnimationFrame(()=>{
          // console.log(Math.round(e.target.getCurrentTime()));
          if (Math.round(e.target.getCurrentTime()) >= 98) {
            this.pause();
            gsap.to(this.dom.openingMovie, {
              opacity: 0,
              duration: 2,
              onComplete: () => {
                _this.dom.openingMovie.remove();
              }
            })
          } else if (this.isPlaying) {
            raf();
          }
        });
      }
      raf();

      // 画質の変更
      // this.player.setPlaybackQuality('hd1080');
      // this.player.setPlaybackQuality('highres');

      // player is replaced by YT script. get the dom again.
      this.dom.player = this.dom.el.querySelector('#opening-youtube');
      // if (this.opening) this.player.pauseVideo();
    });

    this.player.on('stateChange', e => {
      if (e.data === YT.PlayerState.ENDED) {
        // console.log({e})
        // console.log('ENDED')
        // this.player.playVideo();
        this.pause();
      }
      if (e.data === YT.PlayerState.PLAYING) {
        // console.log('PLAYING')
        if (!this.isInit) {
          this.isInit = true;
          this.isPlaying = true;
          this.dom.player.style.opacity = 1;
          this.dom.el.classList.add('is-active');
        }
      }
    });
  }

  setSize(w, h) {    
    // console.log('this.device', this.device)
    if (this.device === 'pc') {
      // console.log('pc')
      if (w / h < 560 / 315) {
        this.dom.player.style.width = `${h * (560 / 315) + 2}px`;
        this.dom.player.style.height = `${h * 2}px`;
      } else {
        this.dom.player.style.width = `${w + 2}px`;
        this.dom.player.style.height = `${h * 2}px`;
      }
    } else {
      // if (w / h < 1 / 1) {
      //   // this.dom.player.style.width = `${h * (1 / 1) + 2}px`;
      //   // this.dom.player.style.height = `${h * 2}px`;
      //   this.dom.player.style.width = `${h}px`;
      //   this.dom.player.style.height = `${h}px`;
      // } else {
      //   // this.dom.player.style.width = `${w + 2}px`;
      //   // this.dom.player.style.height = `${h * 2}px`;
      // }
      // console.log({w})
      this.dom.player.style.width = `${w + 2}px`;
      this.dom.player.style.height = `${h * 1.5 + 2}px`;
    }
  }

  play() {
    if (!this.isPlaying) {
      this.player.playVideo();
      this.Playing = true;
    }
  }

  pause() {
    this.player.pauseVideo();
    this.isPlaying = false;
  }

  checkBreakPoint(_mql) {
		if (!_mql.matches) {
      // console.log('sp');
      this.device = 'sp';
      return;
    } else {
      // console.log('pc');
      this.device = 'pc';
      return;
		}
	}
}

export default OpeningAnimation