import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

class ScrollTriggerClass {
  constructor(elms) {
    this.dom = {elms: elms};
    this.dom.nav = document.querySelectorAll('[data-js="scroll-nav"]');

    this.regex = new RegExp('brandstory*');
    
    this.target = null;
    this.hash = null;
    this.scroll = this.scroll.bind(this);

    gsap.registerPlugin(ScrollTrigger);

    this.init();
  }

  init() {

    [...this.dom.nav].forEach((el) => {
      el.addEventListener('click', this.scroll);
    });

    [...this.dom.elms].forEach((el, index) => {
      ScrollTrigger.batch(el, {
        onEnter: ({progress, direction, isActive}) => {
          this.target = el.dataset.sectionName;
          this.setNavigation();
        },
        onEnterBack: ({progress, direction, isActive}) => {
          this.target = el.dataset.sectionName;
          this.setNavigation();
        },
        start: 'top 50%',
        end: 'bottom 50%',
        markers: false,
      });
    });
  }

  setNavigation() {
    if (this.regex.test(this.target)) {
      document.body.dataset.scrollNav = 'show';

      // 現在カレント表示のナビゲーションをOFFに
      const current = document.querySelector(`[data-is="current"]`);
      if (current) current.removeAttribute('data-is');
      // 新しいカレント対象のナビゲーションをONに
      const nav = document.querySelector(`[data-js="scroll-nav"][data-slug="${this.target}"]`);
      nav.dataset.is = 'current';
    }
    else if (document.body.dataset.scrollNav === 'show') {
      document.body.removeAttribute('data-scroll-nav');
    }
  }

  scroll(e) {
    e.preventDefault();

    this.hash = e.target.dataset.slug;
    const wrapper = document.querySelector(`[data-section-wrapper="brandstory"]`);
    const target = document.querySelector(`[data-section-name="${this.hash}"]`);
    const index = this.hash.substr(-1, 1) - 1;
    
    if (/pagetop/.test(this.hash)) {
      target.scrollIntoView({behavior:'smooth'});
    } else {
      const rect = wrapper.getBoundingClientRect().top;
      const pyo = window.pageYOffset;
      const top = rect + pyo + (window.innerHeight * 2 * index);

      if (top == 0) {
        target.scrollIntoView({behavior:'smooth'});
      } else {
        window.scroll({top: top, behavior: 'smooth'});
      }
    }
  }
}

export default ScrollTriggerClass